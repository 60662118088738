import React, { Fragment } from "react";
import "./style//App.css";
import Privacy from "./Pages/Privacy";
import logo from "./icons/logo.png";
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import Main from "./Pages/Main";
import Terms from "./Pages/Terms";
import NamesCash from "./Pages/NamesCash";
import PageNotFound from "./Pages/PageNotFound";

import SpotTheOdd from "./Pages/SpotTheOdd";
import SignInSignUpPage from "./Pages/SignInSignUpPage";

export default class App extends React.Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Fragment>
            <div className="main">
              <nav className="header">
                <a href="/">
                  <img src={logo} alt="Logo" className="logo" />
                </a>
              </nav>
              <Routes>
                <Route exact path="/" element={<Main />} />

                <Route path="/privacy" element={<Privacy />} />

                <Route path="/terms" element={<Terms />} />

                <Route path="/names-cash" element={<NamesCash />} />

                <Route path="/spot-the-odd" element={<SpotTheOdd />} />

                <Route
                  path="/SignInSignUpPage"
                  element={<SignInSignUpPage />}
                />

                <Route component={PageNotFound} />
              </Routes>
            </div>

            <footer className="footer">
              <div className="footer-container">
                <p style={{ margin: "5px", fontWeight: "0px" }}>
                  261 Bolton Road, Bury, England, BL8 2NZ
                </p>

                <div className="legal-links">
                  <Link to="/privacy" style={{ textDecoration: "none" }}>
                    Privacy
                  </Link>
                  <Link to="/terms" style={{ textDecoration: "none" }}>
                    Terms
                  </Link>
                </div>
              </div>
            </footer>
          </Fragment>
        </BrowserRouter>
      </div>
    );
  }
}
