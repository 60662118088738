import React, { Component } from "react";
import Kylie from "../icons/Names-Cash.png";
import Matrix from "../icons/matrix.png";
import Apple from "../icons/apple.png";
import Google from "../icons/google.png";

class Main extends Component {
  render() {
    return (
      <div className="strip-blue">
        <h1 className="title gradient">
          We create innovative challenging games
        </h1>
        <hr />
        <div className="cards">
          <div className="card c1">
            <h1> NAMES CASH </h1> <p className="thin"> KICKING SOON... </p>
            <div style={{ marginBottom: "20px" }}>
              <img src={Apple} alt="icon" href="" className="store-icon"></img>
            </div>
            <img src={Kylie} alt="icon" className="game-icon"></img>
          </div>
          <div className="card c2">
            <h1> MATRIX POKER </h1> <p className="thin"> Available on: </p>
            <div className="container">
              <a href="https://apps.apple.com/us/app/matrix-poker/id1625830846">
                <img src={Apple} alt="icon" className="store-icon"></img>
              </a>
              <a href="https://play.google.com/store/apps/details?id=co.Vironic.MatrixPoker">
                <img src={Google} alt="icon" className="store-icon"></img>
              </a>
            </div>
            <img src={Matrix} alt="icon" className="game-icon"></img>
            <p className="description"> </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
