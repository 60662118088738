import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Replace with your Firebase project's config object
const firebaseConfig = {
  apiKey: "AIzaSyCGxVonzRd9DgkIeOg2PU1YSbiolFkmvEA",
  authDomain: "spottheodd-95dbc.firebaseapp.com",
  projectId: "spottheodd-95dbc",
  storageBucket: "spottheodd-95dbc.appspot.com",
  messagingSenderId: "702722497766",
  appId: "1:702722497766:web:0789ed66982e8ee3912f27",
  measurementId: "G-81XJF5WWWB",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const SignInSignUpPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [recoveryPhoneNumber, setRecoveryPhoneNumber] = useState("");

  const handleSignIn = () => {
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowVerification(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSignUp = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        `${phoneNumber}@example.com`,
        verificationCode
      )
      .then((userCredential) => {
        // User signed up successfully
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleVerification = () => {
    window.confirmationResult
      .confirm(verificationCode)
      .then((result) => {
        // User signed in successfully
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleForgotPassword = () => {
    firebase
      .auth()
      .verifyPhoneNumber(recoveryPhoneNumber, window.recaptchaVerifier)
      .then((verificationId) => {
        // Save verification ID for later use
        console.log(verificationId);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <h1>Sign In/Sign Up</h1>
      <label>Phone Number:</label>
      <input
        type="tel"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      {!showVerification && <button onClick={handleSignIn}>Sign In</button>}
      {showVerification && (
        <>
          <label>Verification Code:</label>
          <input
            type="tel"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <button onClick={handleVerification}>Verify</button>
        </>
      )}
      {!showVerification && <button onClick={handleSignUp}>Sign Up</button>}
      <button onClick={() => setShowForgotPassword(true)}>
        Forgot Password?
      </button>
      {showForgotPassword && (
        <>
          <label>Recovery Phone Number:</label>
          <input
            type="tel"
            value={recoveryPhoneNumber}
            onChange={(e) => setRecoveryPhoneNumber(e.target.value)}
          />
          <button onClick={handleForgotPassword}>Recover Password</button>
        </>
      )}
    </div>
  );
};

export default SignInSignUpPage;
