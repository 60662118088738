import React, { useState, useEffect } from "react";
import "./SpotTheOdd.css";
import LiveButton from "../Components/LiveButton";

const GRID_SIZE = 5;
const OBJECTS = ["🍎", "🍇", "🍌", "🍊", "🍉", "🍓", "🍒", "🍑", "🍍", "🍋"];

function generateGrid() {
  const grid = new Array(GRID_SIZE)
    .fill(null)
    .map(() => new Array(GRID_SIZE).fill(OBJECTS[1]));
  const oddRow = Math.floor(Math.random() * GRID_SIZE);
  const oddColumn = Math.floor(Math.random() * GRID_SIZE);
  const oddObject = OBJECTS[Math.floor(Math.random() * OBJECTS.length)];
  grid[oddRow][oddColumn] = oddObject;
  return grid;
}

export default function App() {
  const [grid, setGrid] = useState(generateGrid());
  const [round, setRound] = useState(1);
  const [time, setTime] = useState(10);
  const [gameOver, setGameOver] = useState(false);
  const [timerID, setTimerID] = useState(null);

  function handleCellClick(row, col) {
    if (grid[row][col] !== grid[0][0]) {
      setGrid(generateGrid());
      setRound((prevRound) => prevRound + 1);
      setTime(10);
    }
  }

  useEffect(() => {
    if (time === 0) {
      setGrid(generateGrid());
      setRound((prevRound) => prevRound + 1);
      setTime(10);
    }
  }, [time]);

  useEffect(() => {
    if (round > 5) {
      setGameOver(true);
      clearTimeout(timerID);
    }
  }, [round, timerID]);

  useEffect(() => {
    startTiner();
  }, []);

  function startTiner() {
    const id = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
    setTimerID(id);
    return () => clearInterval(id);
  }

  function gameStart(e) {
    e.preventDefault();
    console.log("You clicked submit.");
    setGameOver(false);
    setGrid(generateGrid());
    setRound(0);
    // setTime(10);
    startTiner();
  }

  return (
    <div className="App">
      <LiveButton />
      <h1>Spot the Odd</h1>
      {gameOver ? (
        <>
          <h2>Game Over</h2>
          <button onClick={gameStart}>Start again</button>
        </>
      ) : (
        <>
          <h2>Round {round}</h2>
          <h3>Time Left: {time}s</h3>
          <div className="grid">
            {grid.map((row, rowIndex) => (
              <div key={rowIndex} className="grid-row">
                {row.map((cell, colIndex) => (
                  <div
                    key={`${rowIndex}-${colIndex}`}
                    className={`cell ${cell === grid[0][0] ? "" : "odd"}`}
                    onClick={() => handleCellClick(rowIndex, colIndex)}
                  >
                    {cell}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
