import React from "react";

const PageNotFound = () => {
  return (
    <div id="wrapper" className="wrapper">
      <img src="https://i.imgur.com/qIufhof.png" alt="404" />
      <div id="info">
        <h3 className="text">This page could not be found</h3>
      </div>
    </div>
  );
};

export default PageNotFound;
