import React from "react";

export default function Terms() {
  return (
    <div className="legal">
      <h1> TERMS AND CONDITIONS</h1> <h4>Effective as of 15/09/2022</h4>
      <p>
        These Terms of Use (“Terms”) constitute a binding legal agreement
        between Vironic Ltd. and its affiliates, which shall mean subsidiaries,
        parent companies, joint ventures, and other corporate entities under
        common ownership, (collectively, &quot;Vironic&quot;, &quot;we,&quot;
        “our,” or &quot;us&quot;) and you (&quot;you&quot; or &quot;your&quot;)
        and governs your access and use of all features, content, and other
        services provided by Vironic, including without limitation our website
        https://www.vironic.co (“Website”) and gaming applications
        (&quot;App(s)&quot;; collectively &quot;Services&quot;). By registering
        an account and participating in any competitions and tournaments held in
        the Services, you affirmatively signify that you have read, understood,
        and agreed to be bound by these Terms, our Privacy Policy as well as our
        respective Promotion Rules (“Rules”), each incorporated herein by
        reference. If you do not agree to these Terms Rules or the Privacy
        Policy, you must not register an account or otherwise use or access the
        Services. By registering an account or otherwise accessing the Services,
        to the maximum extent permitted under applicable law, you agree that any
        claim, dispute, or controversy of whatever nature arising out of or
        relating to these Terms and/or your use of the Services shall be
        resolved by final and binding arbitration in accordance with the process
        described in section 16 below.
      </p>
      <h4>1. Use of our Services</h4>
      <p>
        Vironic&#39;s Services include an online arena for mobile game
        challenges, cash competitions, and cash tournaments, using applications,
        tools, and services that we may provide from time to time. Certain
        features and competitions may require you to register with Vironic and
        create an account by providing us certain information about yourself.
        All competitions and tournaments offered on the Services are games of
        skill. Winners are determined solely by the objective criteria described
        in the rules, scoring, and any other applicable documentation associated
        with the competitions. From all entries received for each competition,
        the individuals who use their relevant skill and knowledge to accumulate
        the winning score or points according to the corresponding rules will be
        the winner(s). The Services may not be used for any form of gambling.
      </p>
      <h4>2. Device information</h4>
      <p>
        Using the Services requires an internet connection to our servers, and
        we may need to collect certain information from you and your
        internet-enabled device (“Device”) in order to make the software and
        services available to you, such as hardware system profile data,
        internet connection data, and any other data related to the operation of
        the Services from any device that logs onto the Services using your
        account. We will use this information in accordance with our Privacy
        Policy . We do not warrant that our Services will work on all Devices.
        You are solely responsible for obtaining and maintaining compatible
        Devices necessary to access and use our Services, as updated from time
        to time.
      </p>
      <h4>3. Eligibility</h4>
      <p>
        These Services are intended solely for users who are eighteen (18) years
        of age (unless the applicable age of majority in your state of residence
        is higher) or older, and any registration, use, or access to the
        Services by anyone under 18 is unauthorized, unlicensed, and in
        violation of these Terms. If we have any reason to believe that you are
        under 18 years of age (or the applicable legal age), we may terminate
        your account, delete any content or information that you have submitted
        to the Services, and prohibit you from using or accessing the Services
        (or any portion, aspect or feature thereof). By accessing the and/or
        otherwise using Services, you represent and warrant that you: (i) are
        over the age of eighteen (18) and/or over the legal age of majority
        under applicable law in the jurisdiction in which you reside, (ii) have
        the legal power to form a binding contract with Vironic, (iii) are
        physically located in a jurisdiction in which participation in the
        competition you select is unrestricted by that jurisdiction’s laws, and
        (iv) agree to at all times abide by these Terms and all applicable laws.
        If you do not meet all of these requirements, you must not access and/or
        use any of the Services, and we may suspend or close your account with
        or without notice.
      </p>
      <p>
        Vironic employees and immediate family members (an “Immediate Family
        Member” means any domestic partner or relative of the employee who
        resides at an employee’s residence, including but not limited to
        parents, grandparents, in-laws, children, siblings, and spouses) are not
        permitted to play in any public competitions hosted on the Services.
      </p>
      <p>
        You are responsible for keeping your account information secure. Please
        do not share your account information with anyone and notify us right
        away if you have lost control of your password or you suspect there is
        unauthorized activity in your account. You remain fully liable for any
        actions taken in respect to your account, regardless of whether these
        actions were taken by you or an unauthorized third party that has gained
        access to your account.
      </p>
      <p>
        As the holder of your account, you are solely responsible for complying
        with these Terms, and only you are entitled to all benefits accruing
        thereto. You may not allow any other person to (i) access your account;
        (ii) access the Services through your account; or (iii) accept or use
        prizes from your account. Neither your account nor prizes won from
        participating in competitions are transferable to any other person or
        account.
      </p>
      <p>
        We reserve the right to verify your registration details, such as name,
        address, age, and payment methods used, at any time, by requesting
        certain documents. These documents shall typically include an identity
        card, proof of address such as a utility bill, and proof of your payment
        method. If deemed necessary, we may request that the said document
        copies are notarized, meaning that the documents are stamped and
        attested by a Public Notary. In the event our requests for documents are
        not completed by you, Vironic may at its sole discretion terminate the
        account, and withhold any funds that are present therein.
      </p>
      <p>
        You agree to update any provided information or data or to provide
        additional items as part of ongoing efforts to prevent illegal and
        fraudulent activities, and/or to comply with any other policies or
        protocols we elect to put in place. You agree that all information and
        data that you provide to us either at the time you register for an
        account or at any subsequent time will be truthful, accurate and
        verifiable in all respects and, by providing such information and data,
        you consent to us submitting it to third party providers of age
        verification and identification services to verify your account.
      </p>
      <p>
        We reserve the right at any time to investigate your account, including
        performing background checks and credit checks, in order to ensure
        compliance with these Terms and our obligations and to ensure that no
        improper or illegal activity is or has taken place. In the event that we
        determine that your account has been involved in any illegal or improper
        activity, we reserve the right to terminate your account without notice
        or liability.
      </p>
      <p>
        In order to encourage users to succeed in our games, we may publish data
        relating to the most successful users in the games according to various
        categories of users and statistics, as set upon our sole discretion. We
        may publish such statistics pertaining to successful users and create
        informal competitions between various categories of users in a manner
        that shall be accessible to all users of the game.
      </p>
      <h4>4. Account registration</h4>
      <p>
        When you create an account, you will be asked to provide certain
        information, which may involve: (a) registering a unique username and
        password; (b) providing contact information, such as your name, phone
        number, and email address; (c) payment and billing information; and (d)
        any other information we request on the account registration form. You
        may only register for a single account and may not use or access
        multiple accounts at the same time.
      </p>
      <p>
        You are responsible for keeping your account information secure. Please
        do not share your account information with anyone and notify us right
        away if you have lost control of your password or you suspect there is
        unauthorized activity in your account. You remain fully liable for any
        actions taken in respect to your account, regardless of whether these
        actions were taken by you or an unauthorized third party that has gained
        access to your account.
      </p>
      <p>
        As the holder of your account, you are solely responsible for complying
        with these Terms, and only you are entitled to all benefits accruing
        thereto. You may not allow any other person to (i) access your account;
        (ii) access the Services through your account; or (iii) accept or use
        prizes from your account. Neither your account nor prizes won from
        participating in competitions are transferable to any other person or
        account.
      </p>
      <p>
        We reserve the right to verify your registration details, such as name,
        address, age, and payment methods used, at any time, by requesting
        certain documents. These documents shall typically include an identity
        card, proof of address such as a utility bill, and proof of your payment
        method. If deemed necessary, we may request that the said document
        copies are notarized, meaning that the documents are stamped and
        attested by a Public Notary. In the event our requests for documents are
        not completed by you, Vironic may at its sole discretion terminate the
        account, and withhold any funds that are present therein.
      </p>
      <p>
        You agree to update any provided information or data or to provide
        additional items as part of ongoing efforts to prevent illegal and
        fraudulent activities, and/or to comply with any other policies or
        protocols we elect to put in place. You agree that all information and
        data that you provide to us either at the time you register for an
        account or at any subsequent time will be truthful, accurate and
        verifiable in all respects and, by providing such information and data,
        you consent to us submitting it to third party providers of age
        verification and identification services to verify your account.
      </p>
      <p>
        We reserve the right at any time to investigate your account, including
        performing background checks and credit checks, in order to ensure
        compliance with these Terms and our obligations and to ensure that no
        improper or illegal activity is or has taken place. In the event that we
        determine that your account has been involved in any illegal or improper
        activity, we reserve the right to terminate your account without notice
        or liability.
      </p>
      <p>
        In order to encourage users to succeed in our games, we may publish data
        relating to the most successful users in the games according to various
        categories of users and statistics, as set upon our sole discretion. We
        may publish such statistics pertaining to successful users and create
        informal competitions between various categories of users in a manner
        that shall be accessible to all users of the game.
      </p>
      <h4>5. Compliance with laws</h4>
      <p>
        You acknowledge that various rules, regulations, and laws addressing
        sweepstakes, contests, and tournaments with entry fees and/or prizes may
        govern your participation in competitions (&quot;Gaming Laws&quot;) and
        that Gaming Laws are set up by each individual state, country,
        territory, or jurisdiction. Therefore, we do not offer cash competitions
        (as defined below) to users participating in competitions in any state
        in which such competition violates its gaming laws (&quot;Prohibited
        Jurisdiction&quot;), and if you are located in any Prohibited
        Jurisdiction then you may not participate in cash competitions. In the
        United States, Prohibited Jurisdictions include: Arizona, Arkansas,
        Delaware, Louisiana, Maryland, and South Carolina. For card games,
        Prohibited Jurisdictions include Indiana, Iowa, Maine, and Montana. We
        reserve the right to include additional Prohibited Jurisdictions as
        determined at our sole discretion.
      </p>
      <p>
        You are solely responsible for your compliance with all applicable laws.
        Access to competitions may not be legal for some or all residents of, or
        persons present in, certain jurisdictions. Services and competitions are
        void where prohibited or restricted by applicable law. Your
        participation in competitions is at your own risk, and you agree not to
        hold us responsible or liable if the laws of your jurisdiction restrict
        or prohibit your access or participation in the Services. We make no
        representations or warranties, express or implied, as to the lawfulness
        of your participation in any competition or use of Services, nor shall
        any person affiliated, or claiming affiliation, with us have authority
        to make any such representations or warranties. We reserve the right
        (but have no obligation) to monitor the location from which you access
        the Services, and we may block access from any Prohibited Jurisdiction.
      </p>
      <p>
        You must comply with all applicable export and sanction laws and
        regulations (“Trade Controls”) and may not use, export, or transfer any
        portion of the Services or any related technical information or
        materials, directly or indirectly, except as authorized by such Trade
        Controls. The Trade Controls prohibit the use of the Services by any
        individual located in, under the control of, organized in, or a resident
        of any country or territory which is the target of sanctions by the U.S.
        government (currently, Cuba, Iran, Syria, North Korea, and the Crimea
        region of Ukraine), any countries designated as an “enemy”, that are not
        currently exempted, under Israel’s Trading with the Enemy Ordinance of
        1939 (currently, Syria, Lebanon, and Iran), or any country upon
        Vironic&#39;s sole discretion due to local legislation requirements or
        any other reason upon its sole discretion, or anyone or any entity (or
        anyone or any entity 50% or more owned by such individual or entities)
        on any list of prohibited persons or entities maintained by the U.S.,
        E.U. or UK governments, or by the jurisdictions in which the Services
        were obtained.
      </p>
      <h4>6. Prohibited uses</h4>
      <p>
        You may use the Services only for lawful purposes. You are solely
        responsible for all of your activity in connection with the Services.
      </p>
      <p>
        You may not (and shall not permit any third party to) either (a) take
        any action or (b) upload, submit, post, or otherwise distribute or
        facilitate distribution of any content or user submission on or through
        the Services that: (i) is illegal, violent, threatening, abusive,
        invasive of any person&#39;s privacy, harassing, defamatory, libelous,
        false, deceptive, fraudulent, misleading, untruthful, tortuous, obscene,
        offensive, pornographic, or otherwise inappropriate, as Vironic may
        determine in its sole discretion; (ii) infringes, misappropriates, uses
        or discloses without authorization, or otherwise violates any
        intellectual property rights or proprietary rights of any third party;
        (iii) violates any right of publicity, or other right of any person or
        entity, or any law or contractual obligation, including without
        limitation rights pursuant to data protection, anti- spam and privacy
        laws and regulations; (iv) involves commercial activity not expressly
        permitted by Vironic; (v) contains any harmful or malicious software or
        computer codes, files or programs that are designed or intended to
        damage, disrupt, interfere with, or limit, the proper functionality of
        any software or hardware, or that enables to obtain unauthorized access
        to any system, data, password or other information of Vironic, its
        users, or any other individual or entity; (vi) uses or launches any
        automated system, including without limitation, “robots,” “spiders,”
        “offline readers”, etc., that access the Services in a manner that sends
        more request messages to Vironic&#39;s servers than a human can
        reasonably produce in the same period of time; or (vii) impersonates any
        individual or entity, including, without limitation, employees or
        representatives of Vironic. You shall be solely responsible for any and
        all content of any kind that you make available (by uploading or
        otherwise) or use through the Services.
      </p>
      <p>
        You represent and warrant to us that you will not engage in any activity
        that interrupts or attempts to interrupt the operation of the Services.
        Anyone who engages in, participates in, or displays behavior that may be
        interpreted, in our sole discretion, as unfair methods in participating
        in the Services, including but not limited to, the opening and/or use of
        multiple accounts; the use of unauthorized or altered software or
        hardware to assist play; intentionally poor play in certain games to
        achieve competitive advantage; collusion with other players (e.g.
        intentionally losing matches in cash competitions); deliberate transfer
        of money between accounts (e.g. money laundering); harassment of other
        participants; posting objectionable material; breach of these Terms;
        breach of security of your account; or any other act (whether through
        the use of automated technology or otherwise) that unfairly alters your
        chance of winning or constitutes the commission of fraud (collectively,
        &quot;Abuse&quot;), will be subject to immediate sanction (as determined
        by us in our sole discretion), which may include, without limitation:
        (1) immediate termination of your account and blocking of your access to
        the Services; (2) any prizes that you may otherwise have been entitled
        to receive shall be void and forfeited; and (3) any prizes received by
        you shall be subject to disgorgement and/or recoupment. In addition to
        the foregoing, we reserve the right to disclose or report any money
        laundering or other illegal activity to law enforcement and regulatory
        authorities.
      </p>
      <p>
        Any attempt to deliberately damage the Services or undermine the
        legitimate operation of any competition is a violation of criminal
        and/or civil laws and should such an attempt be made, Vironic reserves
        the right to seek damages and other remedies from you to the fullest
        extent permitted by law.
      </p>
      <p>
        You further acknowledge that the forfeiture and/or return of any prize
        as a result of a violation of these Terms shall in no way prevent
        Vironic from pursuing criminal or civil proceedings in connection with
        any Abuse.
      </p>
      <h4>7. Third-party services</h4>
      <p>
        The Services may provide links to third-party websites, apps, services,
        offerings, products or other activities (“Third Party Services”) that
        are not owned or controlled by us. These links are provided as a
        convenience to you and do not signify that Vironic endorses or is liable
        in any way for the content, products, services or materials displayed or
        offered by such Third Party Services. Your use of such Third Party
        Services is made at your own risk and shall be subject to the terms of
        use of such Third Party Services. You acknowledge that Vironic has no
        liability to your use of Third Party Services. Any problems or
        complaints that you may have with regard to such Third Party Services
        should be directed to such third parties.
      </p>
      <p>
        If you have downloaded the Apps from the Apple, Inc. (&quot;Apple&quot;)
        App Store or if you are using the Apps on an iOS device, you acknowledge
        that you have read, understood, and agreed to the following notice
        regarding Apple. These Terms are between you and Vironic only, not with
        Apple, and Apple is not responsible for the Services and the content
        thereof. Apple has no obligation whatsoever to furnish any maintenance
        and support services with respect to the Services. In the event of any
        failure of the Services to conform to any applicable warranty, then you
        may notify Apple and Apple will refund any applicable purchase price for
        the App to you; and, to the maximum extent permitted by applicable law,
        Apple has no other warranty obligation whatsoever with respect to the
        Service. Apple is not responsible for addressing any claims by you or
        any third party relating to the Services or your possession and/or use
        of the Services, including: (1) product liability claims; (2) any claim
        that the Services fail to conform to any applicable legal or regulatory
        requirement; and (3) claims arising under consumer protection or similar
        legislation. Apple is not responsible for the investigation, defense,
        settlement, and discharge of any third party claim that the Services
        and/or your possession and use of the Apps infringe that third
        party&#39;s intellectual property rights. You agree to comply with any
        applicable third-party terms when using the Services. Apple, and
        Apple&#39;s subsidiaries, are third-party beneficiaries of these Terms,
        and upon your acceptance of these Terms, Apple will have the right (and
        will be deemed to have accepted the right) to enforce this Agreement
        against you as a third-party beneficiary of these Terms. You hereby
        represent and warrant that (1) you are not located in a country that is
        subject to a U.S. Government embargo, or that has been designated by the
        U.S. Government as a &quot;terrorist supporting&quot; country; and (2)
        you are not listed on any U.S. Government list of prohibited or
        restricted parties.
      </p>
      <h4>8. Limitation of liability</h4>
      <p>
        By using the Services, you understand and agree that Vironic&#39;s
        liability in connection with your use of the Services is as set forth
        below. Under no circumstances shall Vironic, its parents, subsidiaries,
        or affiliates, or the directors, officers, employees, or other
        representatives of each of them (collectively, the &quot;Vironic
        Entities and Individuals&quot;), be liable to you for any loss or
        damages of any kind (including, without limitation, for any special,
        direct, indirect, incidental, exemplary, economic, punitive, or
        consequential damages) that are directly or indirectly related to (1)
        the Website, the Services, or your uploaded information; (2) the use of,
        inability to use, or performance of the Services; (3) any action taken
        in connection with an investigation by Vironic or law enforcement
        authorities regarding your use of the Services; (4) any action taken in
        connection with copyright owners; or (5) any errors, omissions or
        defects in the Services&#39; technical operation, even if foreseeable or
        even if the Vironic Entities and Individuals have been advised of the
        possibility of such damages, whether in an action of contract,
        negligence, or strict liability tort. In no event will the Vironic
        Entities and Individuals be liable to you or anyone else for loss or
        injury, including, without limitation, death or personal injury, arising
        from your use of the Services. Some states do not allow the exclusion or
        limitation of incidental or consequential damages, so the above
        limitation or exclusion may not apply to you. In such cases, our
        liability will be limited to the fullest extent permitted by applicable
        law.
      </p>
      <p>
        In no event shall the Vironic Entities and Individuals&#39; total
        liability to you for all damages, losses, or causes of action exceed one
        hundred U.S Dollars ($100). The Vironic Entities and Individuals are not
        responsible for any damage to your and any third parties&#39; computer,
        hardware, computer software, or other equipment or technology including,
        without limitation, damage from any security breach or from any virus,
        bug, tampering, fraud, error, omission, interruption, defect, delay in
        operation or transmission, computer line or network failure or any other
        technical or other malfunction. If you are dissatisfied with any of the
        Services, your sole and exclusive remedy is to discontinue accessing and
        using the Services. You recognize and confirm that in the event you
        incur any damages, losses, or injuries that arise out of Vironic&#39;s
        acts or omissions, the damages, if any, caused to you are not
        irreparable or sufficient to entitle you to an injunction preventing any
        exploitation of any Services or other properties owned or controlled by
        Vironic and/or its parents, subsidiaries, and/or affiliates, and you
        will have no rights to enjoin or restrain the development, production,
        distribution, advertising, exhibition or exploitation of the Services,
        your uploaded information on the Services, or any and all activities or
        actions related thereto.
      </p>
      <p>
        By accessing the Services, you understand that you may be waiving rights
        with respect to claims that are at this time unknown or unsuspected.
        Accordingly, you agree to waive the benefit of any law, including, to
        the extent applicable, California Civil Code section 1542, that
        otherwise might limit your waiver of such claims.
      </p>
      <h4>9. Disclaimer of warranties</h4>
      <p>
        The Services are provided on an &quot;as is&quot; and &quot;as
        available&quot; basis, without any warranty of any kind, either express
        or implied. Vironic strives to keep its Services up and running;
        however, all online services suffer occasional disruptions and outages.
      </p>
      <p>
        In addition, Vironic is not responsible in any way for the games you
        play or for your Device, including for any communication or other errors
        in such games or Device, and such errors might be counted as losses in
        competitions you participate in. Vironic does not provide any warranty
        regarding the Services, Website or any service or content made available
        through the Services and expressly disclaims: availability, the accuracy
        of the information displayed about game statistics, technical errors in
        the games, usability, quality, appropriateness, reliability, timeliness,
        serviceability, warranty of title, non- infringement, merchantability,
        legality, or fitness for a particular purpose. Vironic does not warrant
        or guarantee protection from viruses or other computer system malware.
        Vironic does not claim or guarantee that the Services shall be
        uninterrupted, or that Services shall be error-free. Vironic does not
        guarantee that any competitions you participate in will be fair or that
        other players will not cheat in such games. We do not and cannot
        guarantee that the use of our Services will generate you any profits. We
        do not and cannot take responsibility for any losses to your account.
        Some jurisdictions limit or do not allow the disclaimer of implied or
        other warranties so the above disclaimer may not apply to the extent
        such jurisdiction&#39;s law is applicable to this agreement.
      </p>
      <h4>10. Vironic’s intellectual property rights</h4>
      <p>
        The Apps, Website, images, text, graphics, illustrations, trademarks,
        brands, service marks, trade dress, copyrights, photographs, audio,
        videos and music, logos, designs, Virtual Items, and any part thereof,
        including derivative works, of Vironic and other companies serviced by
        Vironic are the property of Vironic or their respective owners. As a
        user of the Services, you agree not to use, copy, reproduce, publish or
        borrow any other content or trademarked work without explicit permission
        from Vironic. Except as explicitly provided herein, nothing in these
        Terms shall be deemed to create a license in or under any such
        intellectual property rights, and you agree not to sell, license, rent,
        modify, distribute, copy, reproduce, transmit, publicly display,
        publicly perform, publish, adapt, edit or create derivative works from
        any materials or content accessible on the Services. You may choose to
        or we may invite you to submit comments or feedback about the Services,
        including without limitation about how to improve the Services
        (“Feedback”). By submitting any Feedback, you agree that your disclosure
        is gratuitous, unsolicited, and without restriction and will not place
        Vironic under any fiduciary or other obligation, that we are free to
        disclose the Feedback on a non-confidential basis to anyone or otherwise
        use the Feedback without any additional compensation to you. Except as
        expressly set forth herein, no right or license is granted hereunder,
        express or implied, to any intellectual property rights and your use of
        the Services does not convey or imply the rights to use the Services in
        combination with any other information or products.
      </p>
      <h4>11. Virtual items</h4>
      <p>
        The Services may include an opportunity to earn or obtain virtual,
        in-game currency, including but not limited to virtual coins, points,
        credits, bonuses, collectibles, and/or chips all for use in the Services
        (&quot;Virtual Items&quot;). Virtual Items can be either won or obtained
        within the Services. Virtual Items are licensed to you by us for your
        personal use through the Services, subject to the limitations and other
        terms set out in greater detail below.
      </p>
      <p>
        Virtual Items can never be redeemed or cashed out for “real world”
        money, goods, or any other item of monetary value from Vironic or any
        other party. You understand that you have no right or title in the
        Virtual Items appearing or originating in any of our Services, or any
        other attributes associated with an account or stored on the Services.
        Your acquiring of Virtual Items is final and is not refundable,
        transferable or exchangeable, except at Vironic&#39;s sole discretion.
        You may not transfer, purchase, sell, or exchange Virtual Items except
        as permitted from within the Services. You may not attempt to sell, give
        or trade in the &quot;real world&quot; anything that appears or
        originates in the Services unless otherwise expressly authorized by
        Vironic in writing.
      </p>
      <p>
        Accordingly, you may not sublicense, trade, sell or attempt to sell
        in-game Virtual Items for &quot;real&quot; money, or exchange Virtual
        Items for value of any kind outside of the Services, without
        Vironic&#39;s written permission. Doing so is a violation of these Terms
        and may result in termination of your account and/or legal action taken
        against you, any such transfer or attempted transfer is prohibited and
        void. Other than a limited, personal, revocable, non- transferable,
        non-sublicensable license to use the Virtual Items with the Services,
        you have no right or title in or to any such Virtual Items appearing or
        originating with the Services, or any other attributes associated with
        the use of the Services or stored within the Services. Vironic retains
        the right to manage, regulate, control, modify and/or eliminate Virtual
        Items at its sole discretion, and Vironic shall have no liability to you
        or anyone for the exercise of such rights. Prices and availability of
        Virtual Items are subject to change without notice. In addition to the
        foregoing, Vironic may selectively remove or revoke Virtual Items
        associated with your account at its sole discretion.
      </p>
      <p>
        Virtual Items may only be held by legal residents of countries where
        access to and use of the Services are permitted. Virtual Items may only
        be acquired or awarded from us or through means we provide on our
        Services or otherwise expressly authorize. Vironic does not recognize
        any purchases or transfers made outside of the Service on any other
        platform or e-commerce website, and shall not be liable for any claims
        or damages caused to the users with respect to Virtual Items purchased
        or obtained from third parties, and not through the means provided
        within the Services.
      </p>
      <p>
        You acknowledge that Vironic is not required to provide a refund for any
        other reason and that you will not receive money or other compensation
        for unused Virtual Items when an account is closed, whether such closure
        was voluntary or involuntary. All Virtual Items are forfeited by you if
        your account is terminated or suspended for any reason, in Vironic&#39;s
        sole and absolute discretion, or if the Services are no longer
        available. If your account is terminated, suspended and/or if any
        Virtual Items are selectively removed or revoked from your account, no
        refund will be granted, no Virtual Items will be credited to you or
        converted to cash or other forms of reimbursement.
      </p>
      <h4>12. License to use the Services</h4>
      <p>
        Subject to these Terms, we grant to you a personal, non-exclusive,
        limited, non-transferable, non-assignable, non-sublicensable, limited
        license to install and run the Services on a Device owned or controlled
        by you, solely for the purpose of accessing and using the Services in
        accordance with these Terms. You may not network the software among
        devices. You may not directly or indirectly, or authorize any person or
        entity to: (i) reverse engineer, decompile, disassemble, re-engineer or
        otherwise create or attempt to create or permit, allow, or assist others
        to create the source code of the Services, or its structural framework;
        (ii) create derivative works of the Services; (iii) use the Services in
        whole or in part for any purpose except as expressly provided herein; or
        (iv) disable or circumvent any access control or related device, process
        or procedure established with respect to the Services. You are
        responsible for all use of the Services that is under your possession or
        control.
      </p>
      <h4>13. Winnings, account funds, and payments</h4>
      <p>
        All fees and payments for Services that require your payments
        (&quot;Fees&quot;) shall be made known to you prior to entry in any
        competition or tournament. If you agree to enter a competition, you
        agree to pay those Fees associated with the entry. All Fees must be
        prepaid, and are non-refundable. You are fully responsible and liable
        for all charges, deposits, and withdrawals made under your account,
        including any unauthorized charges, deposits, or withdrawals. We may
        change the price of Services at any time, but no price change will
        affect your past purchases.
      </p>
      <p>
        We may require you to pay Sales tax, VAT, and GST, as required by
        applicable law. Please note that all taxes payable will calculate and
        added on top of any withdrawal or deposit amounts, as applicable. You
        agree to pay us the applicable charges for any Fees, including
        applicable taxes incurred by you or anyone using an account registered
        to you, using a valid charge card, or another payment method we may
        accept in accordance with the billing terms and prices in effect at the
        time the fee or charge becomes payable.
      </p>
      <p>
        Other than charges to your account, you agree to notify us about any
        billing problems or discrepancies within 30 days after they first appear
        on your account statement. If you do not bring them to our attention
        within 30 days, you agree that you waive your right to dispute such
        problems or discrepancies. You are responsible for and agree to
        reimburse us for all reversals, charge-backs, claims, fees, fines,
        penalties, and other liability incurred by us (including costs and
        related expenses) that were caused by or arising out of payments that
        you authorized or accepted. You understand that we may suspend or
        terminate your account if for any reason a charge you authorize us to
        make to your credit card or other method of payment cannot be processed
        or is returned to us unpaid and, if such event occurs, you shall
        immediately remit to us payment for such charge through an alternative
        payment method.
      </p>
      <p>
        We may change Fees with or without notice to you. By providing us with a
        payment method, you (i) represent that you are authorized to use the
        payment method that you provided and that any payment information you
        provide is true and accurate; and (ii) authorize us to charge you for
        the Services using your payment method. We may bill you (a) in advance;
        (b) at the time of purchase; or (c) shortly after purchase, in our sole
        discretion. If you believe that you have paid any Fees in error, you
        must notify us within 30 days after the error occurs. We will then
        promptly investigate the charge. If you don&#39;t notify us within that
        time, we&#39;ll not be liable for any losses resulting from the error
        and we won&#39;t be required to correct the error or provide a refund.
        If we identify a Fee error, we will correct that error within 90 days.
        You must pay for all reasonable costs we incur to collect any past due
        amounts, including without limitation reasonable attorneys&#39; fees and
        other legal fees and costs.
      </p>
      <p>
        If you play competition without depositing U.S. Dollars into your
        account for that competition, then you are a &quot;Non-Cash Player&quot;
        with respect to such competition. However, if you play in a competition
        that requires an entry fee paid in U.S. Dollars (&quot;Cash
        Competition&quot;), then you are a &quot;Cash Player&quot;. As a Cash
        Player, you hereby acknowledge that we reserve the right to change the
        following at our sole discretion, without giving you prior notice: (i)
        any method for evaluating your funds; and (ii) any withdrawal related
        rules, including the minimum withdrawal amount, withdrawal methods and
        withdrawal fees. Upon a withdrawal request, you may be required to
        submit the following current and correct information: your full name,
        your permanent residential address, your ID, your phone number, and your
        credit card or other payment information. Failure to provide the
        required information may result in our inability to process your
        withdrawal for any winnings. Participating in cash competitions may, in
        our sole discretion, require establishing a positive account balance
        prior to entry. If you are a Cash Player, by submitting this
        information, you consent to allow us to share your personal and payment
        information in confidence with third-party service providers for the
        purposes of validating your identity and assessing the transaction risk
        associated with accepting your selected method of payment, and for any
        other purposes as detailed in our Privacy Policy. In addition, Vironic
        reserves the right to issue or process withdrawals solely via payment
        methods supported by Vironic. If you make a credit card deposit, we may
        submit an authorization request to the issuing bank of at least ten U.S.
        Dollars ($10.00) to your credit limit, even if the actual amount charged
        may be lower. In addition, your personal account (whether it&#39;s a
        bank account, credit card, PayPal or other) may incur additional fees
        and/or commissions for receiving funds from your Vironic account. We are
        not responsible for paying those fees and/or commissions even if such
        fees/commissions exceed the withdrawn amount itself.
      </p>
      <p>
        If you are a Cash Player then we may, from time to time in our sole
        discretion, grant you free bonus funds and/or credits (“Bonus Funds”).
        Bonus Funds can be used to enter Cash Competitions but cannot be
        withdrawn or used for any other service. When you enter a Cash
        Competition, we may deduct a certain amount of the Bonus Funds as an
        entry fee. Notwithstanding the foregoing, additional Bonus Funds will be
        used to enter Cash Competitions if it is the only currency available in
        your account. When you win a Cash Competition, any Bonus Funds that you
        have used to pay the entry fee will be returned to you and any
        additional winnings beyond your entry fee will be paid in U.S. Dollars.
        If you initiate a withdrawal of funds from your account, you will
        forfeit all Bonus Funds currently in your account. If you do not enter a
        Cash Competition within a continuous ninety (90) day period, all Bonus
        Funds in your account will be forfeited.
      </p>
      <p>
        If you are a Cash Player, you may request a withdrawal of funds from
        your available account balance at any time. Processing of requested
        funds may take up to ninety (90) days; provided, however, that we may
        freeze your account and/or delay a request for withdrawal of funds
        pending completion of any investigation of reported or suspected abuse
        or fraud, verification of eligibility, or to comply with applicable
        laws.
      </p>
      <p>
        If you choose to close your account, any funds in your account will be
        forfeited. If you want to withdraw funds from your account before
        closing it, you must request to do so prior to terminating your account.
        If we unilaterally close or terminate your account due to your violation
        (as determined in our sole discretion) of these Terms, funds in your
        account may be forfeited and not returned to you.
      </p>
      <p>
        If your account is inactive (i.e. you have not entered at least one (1)
        competition or tournament) for six (6) consecutive months or more, we
        reserve the right to charge a maintenance fee of $2.00 per month (the
        “Monthly Maintenance Fee”). After five (5) or more months of inactivity,
        you will be notified by email associated with your account that if your
        account remains inactive for one (1) more month we may close it, at our
        sole discretion. The Monthly Maintenance Fee will be deducted from your
        account for each consecutive month that it remains inactive. The Monthly
        Maintenance Fee will not be deducted from your account if there are no
        funds in your account.
      </p>
      <p>
        Unless otherwise required by law, all Fees are final and no refunds are
        given.
      </p>
      <p>
        If you are eligible to receive prizes, we may require that you provide
        us with proof that you are, or were at the time of your participation in
        the competition, eligible to participate in accordance with these Terms
        and that your participation was in accordance with these Terms. If you
        do not provide us with such proof to our reasonable satisfaction, then
        you will not receive the prize. If you receive a prize in error, we may
        reverse or require the return of the prize. You agree to cooperate with
        us in our efforts to do this. We may also reduce the payment to you
        without notice to adjust for any previous overpayment.
      </p>
      <p>
        Competition results and prize calculations are based on the final
        statistics and scoring results at the completion of the competition.
        Once competition results are reviewed and graded, prizes are awarded.
        The scoring results of a competition will not be changed regardless of
        any official statistics or scoring adjustments made at later times or
        dates, except at Vironic’s sole discretion. Vironic reserves the right
        to cancel competitions at any time. In the event of a cancellation, all
        entry fees will be refunded to you except as specifically provided in
        these Terms or applicable rules.
      </p>
      <p>
        Guaranteed prizes may be offered in connection with some of the
        competitions offered by the Services and will be made known in the
        Rules. Each competition is governed by its own set of rules. Vironic
        encourages you to read such rules prior to entry.
      </p>
      <p>
        You must promptly notify us of any changes to your credit card account
        number, its expiration date and/or your billing address, or if your
        credit card expires or is canceled for any reason. We are not liable for
        any loss caused by any unauthorized use of your credit card or another
        method of payment by a third party in connection with the Services. Any
        attempt to defraud through the use of credit cards or other methods of
        payment, regardless of the outcome, or any failure by you to honor
        legitimate charges or requests for payment, will result in immediate
        termination of your account, forfeiture of winnings, and pursuit of
        civil litigation and/or criminal prosecution.
      </p>
      <p>
        You are solely responsible for recording, paying, and accounting to any
        relevant government, taxation, or other authority for any tax or other
        levy that may be payable on any winnings paid to you. We may send you
        federal or state tax forms and/or other appropriate form(s) if your
        total winnings equal six hundred dollars ($600.00) or more in any given
        calendar year. Without limiting the foregoing, we may withhold from your
        existing account balance and/or from future winnings any amount required
        to be withheld by applicable laws, including amounts due in connection
        with your failure to complete relevant tax documentation, but you remain
        solely responsible for paying all federal, state and other taxes in
        accordance with all applicable laws.
      </p>
      <h4>14. Indemnification</h4>
      <p>
        You agree to defend, indemnify, and hold Vironic, its affiliates,
        officers, and employees harmless from any and all claims, liabilities,
        costs, and expenses, including reasonable attorneys&#39; fees, arising
        in any way from your use of and access to the Services, including,
        without limitation, (i) any data or work transmitted or received by you;
        (ii) your violation of any term of these Terms, including without
        limitation, your breach of any of the representations and warranties
        above; (iii) your violation of any third-party right, including without
        limitation any right of privacy, publicity rights or intellectual
        property rights; (iv) your violation of any law, rule or regulation of
        any country as applicable to you and Vironic; (v) any claims or damages
        that arise as a result of any of your user submissions or any other
        content that is submitted via your account; or (vi) any other party’s
        access and use of the Service with your unique username, password or
        other appropriate security code.
      </p>
      <h4>15. Term and termination</h4>
      <p>
        These terms apply to you and to us from the date that you accept them as
        provided above. You may terminate your Vironic account at any time and
        for any reason by sending us a support request at: support@vironic.co or
        following the instructions provided in the Website or Apps. Upon
        termination of your account, you must immediately discontinue the use of
        the Services and your account. Immediately upon termination of your
        account, all licenses, and rights granted to you under these Terms
        automatically terminate. Any and all terms and conditions within these
        Terms that should, by their nature, survive termination of these Terms,
        will survive such termination.
      </p>
      <h4>16. Agreement to arbitrate/class action waiver</h4>
      <p>
        Vironic may permanently or temporarily terminate, suspend, or otherwise
        refuse to permit your access to the Services without notice and
        liability upon any violation of these Terms. Upon termination for any
        reason, you continue to be bound by these Terms.
      </p>
      <h4>17. Modification of Terms or Services</h4>
      <p>
        You agree that any claim or dispute at law or equity that has arisen or
        may arise between you and Vironic relating in any way to or arising out
        of these Terms or your use of, or access to the Services, will be
        resolved in accordance with the provisions set forth in this Section 16.
        Please read this section carefully. It affects your rights and will have
        a substantial impact on how claims you may have against each other are
        resolved.
      </p>
      <p>
        Legal notices shall be served at the address provided in the contact
        section (in case Vironic is served) or your email address on file with
        us (in case you are served). Notice by us to you shall be deemed given
        twenty-four (24) hours after the email is sent. Alternatively, we may
        give you legal notice by mail to the physical address associated with
        your account. In such case, notice shall be deemed given three (3) days
        after the date of mailing, regardless of whether any such notice is
        returned to us. It is your responsibility to keep your contact
        information updated.
      </p>
      <p>
        We will attempt to resolve any dispute through informal negotiation
        within sixty (60) days from the date the notice of dispute is sent. If
        the dispute is not resolved within such time period, you agree that any
        and all disputes or claims that have arisen or may arise between you and
        Vironic relating in any way to or arising out of these Terms or your use
        of or access to the Services shall be resolved exclusively through final
        and binding arbitration. The FAA governs the interpretation and
        enforcement of this agreement to arbitrate.
      </p>
      <p>
        In all events, each party hereby knowingly, voluntary and intentionally,
        waives (to the extent permitted by applicable law) any right it may have
        to a trial by jury of any dispute arising under or relating to these
        Terms or your use of or access to the Services. The parties further
        agree that, if and to the extent this agreement to arbitration does not
        apply to any claim, that claim will be tried before a judge sitting
        without a jury.
      </p>
      <p>
        The arbitrator, and not any federal, state, or local court or agency,
        shall have exclusive authority to resolve any dispute arising out of or
        relating to the interpretation, applicability, enforceability or
        formation of this agreement to arbitrate, any part of it, or of the
        Terms including, but not limited to, any claim that all or any part of
        this agreement to arbitrate or the Terms is void or voidable.
      </p>
      <p>
        The arbitration will be conducted by the American Arbitration
        Association (“AAA”) under its rules and procedures, including the AAA’s
        commercial arbitration rules (as applicable), as modified by this
        agreement.
      </p>
      <p>
        The arbitration shall be held in London, UK or at another mutually
        agreed location. If the value of the relief sought is $10,000 or less,
        you or Vironic may elect to have the arbitration conducted by telephone
        or based solely on written submissions, which election shall be binding
        on you and Vironic, subject to the arbitrator’s discretion to require an
        in-person hearing. The arbitrator’s award shall be final and binding,
        and judgment on the award rendered by the arbitrator may be entered in
        any court having jurisdiction thereof.
      </p>
      <p>
        To the extent permitted by applicable law, any claim or dispute under
        these Terms and use of the Services must be filed within one (1) year
        from the date of the cause of action. If a claim or dispute isn’t filed
        within one year, you acknowledge that you shall have waived and will be
        deemed permanently barred from bringing such dispute.
      </p>
      <p>
        With the exception of any of the provisions in the class action waiver,
        if an arbitrator or court decides that any part of this agreement to
        arbitrate is invalid or unenforceable, the other parts of this agreement
        to arbitrate shall still apply.
      </p>
      <h4>17. Modification of Terms or Services</h4>
      <p>
        We reserve the right, at any time in our sole discretion, to amend or
        replace any part of these Terms and any document referred to herein, or
        any part thereof, at any time, without prior notice. You agree that we
        may notify you of any updated or new Terms by posting notice on the
        Services so that they are accessible via a link from the home page or by
        providing you notice at the email address associated with your account.
        All such changes are effective immediately when we post them, or such
        later date as may be specified in the notice of updated Terms. Your
        continued use of the Services after such notice confirms your consent to
        and acceptance of such amendment. The most current version of these
        Terms will govern your use and access of the Services, including without
        limitation any content made available on or through the Services. If you
        object to any such changes, your sole recourse is to cease accessing the
        Services.
      </p>
      <p>
        We reserve the right, at any time and from time to time, temporarily or
        permanently, with or without notice, in whole or in part, to: (i) stop
        offering and/or supporting the Services or any particular game or part
        of the Services; (ii) terminate or suspend your license to use the
        Services or any part of it; (iii) modify or discontinue the Services;
        (iv) modify or remove any of the information contained in the Services;
        (v) limit the Services&#39; availability to any person, geographic area,
        or jurisdiction we choose; (vi) charge fees in connection with the use
        of the Services; (vii) modify and/or waive any fees charged in
        connection with the Services; and/or (viii) offer opportunities to some
        and all users of the Services. If that happens, Vironic is not required
        to provide refunds, benefits or other compensation to players in
        connection with discontinued elements of the Services or for virtual
        goods previously awarded or purchased. You agree that we will not be
        liable to you or to any third party for any modification, suspension or
        discontinuance of the Services, in whole or in part. Your continued use
        of the Services after such changes will indicate your acceptance of such
        changes in the Services and in these Terms.
      </p>
      <h4>18. Governing law</h4>
      <p>
        We reserve the right, at any time and from time to time, temporarily or
        permanently, with or without notice, in whole or in part, to: (i) stop
        offering and/or supporting the Services or any particular game or part
        of the Services; (ii) terminate or suspend your license to use the
        Services or any part of it; (iii) modify or discontinue the Services;
        (iv) modify or remove any of the information contained in the Services;
        (v) limit the Services&#39; availability to any person, geographic area,
        or jurisdiction we choose; (vi) charge fees in connection with the use
        of the Services; (vii) modify and/or waive any fees charged in
        connection with the Services; and/or (viii) offer opportunities to some
        and all users of the Services. If that happens, Vironic is not required
        to provide refunds, benefits or other compensation to players in
        connection with discontinued elements of the Services or for virtual
        goods previously awarded or purchased. You agree that we will not be
        liable to you or to any third party for any modification, suspension or
        discontinuance of the Services, in whole or in part. Your continued use
        of the Services after such changes will indicate your acceptance of such
        changes in the Services and in these Terms.
      </p>
      <p>
        Otherwise, these Terms shall be governed by and construed in accordance
        with the laws of the State of Israel, without regard to conflict of law
        principles thereof. You hereby consent to the exclusive personal
        jurisdiction and venue of London, UK.
      </p>
      <h4>19. No Waiver and Force Majeure</h4>
      <p>
        Vironic&#39;s failure to exercise or enforce any right or provision in
        these Terms shall not be considered a waiver of such right or provision,
        unless agreed upon in writing. Vironic will not be responsible for any
        failures to fulfill any obligations due to causes beyond its reasonable
        control.
      </p>
      <h4>20. Survival</h4>
      <p>
        You agree that the following provisions of these Terms shall survive
        termination of the Services, for any reason whatever: Compliance with,
        laws, Prohibited uses, Limitation of liability, Indemnification,
        Vironic&#39;s intellectual property rights, Term and termination,
        Agreement to arbitrate/class action waiver, and Governing law.
      </p>
      <h4>21. Assignment of rights</h4>
      <p>
        You may not transfer, assign, sub-license, or pledge in any manner
        whatsoever your account or any of your rights or obligations under these
        Terms. Vironic may transfer, assign, sub-license, or pledge in any
        manner whatsoever any of its rights and obligations under these Terms to
        any subsidiary, affiliate, or successor thereof or to any third party
        whatsoever, without notifying you or receiving your consent.
      </p>
      <h4>22. Notification procedures</h4>
      <p>
        Vironic may provide notifications, whether such notifications are
        required by law or are for marketing or other business-related purposes,
        to you via email notice, written or hard copy notice, or through
        conspicuous posting of such notice on the Services, as determined by
        Vironic in our sole discretion. Vironic reserves the right to determine
        the form and means of providing notifications to you, provided that you
        may opt out of certain means of notification as described in these Terms
        or Vironic’s Privacy Policy.
      </p>
      <h4>23. Severability of clauses</h4>
      <p>
        The Terms posted on this page, and the policies and agreements that are
        incorporated herein by reference, as amended, constitute the entire
        Terms between you and Vironic. If any part of these Terms is held by a
        court of law to be invalid or unenforceable, that portion shall be
        construed in a manner consistent with applicable law to reflect the
        original intentions of the parties, and the remaining portions shall
        remain in full force and effect.
      </p>
      <h4>24. Language</h4>
      <p>
        You acknowledge that these Terms, and all related documents (including
        any rules and the privacy policy) have been prepared in English. If
        these Terms are translated into another language, the English language
        text shall in any event prevail.
      </p>
      <h4>25. Contact us</h4>
      <p>
        If you have any questions about these Terms or Vironic’s Services in
        general, please contact us at: support@vironic.co Subject to the content
        of your inquiry, Vironic may request that you provide additional
        information in order to allow the appropriate handling of your inquiry.
      </p>
    </div>
  );
}
