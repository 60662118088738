import React, { Component } from "react";
import namesCashMockup from "../icons/names-cash-mockup.png";
import JoinIcon from "../icons/join.png";
import ClockIcon from "../icons/clock.png";
import GuessIcon from "../icons/guess.png";
import WinnerIcon from "../icons/winner.png";
import MoneyBag from "../icons/money-bag.png";

export default class NamesCash extends Component {
  render() {
    return (
      <div>
        <div className="main-container">
          <div className="inner-container">
            <div className="text-container">
              <h2 className="sub-title">
                LIVE scrabble competitions with cash prizes
              </h2>
            </div>
            <img src={namesCashMockup} alt="Mockup" className="main-img" />
          </div>
        </div>
        <div className="middle-container">
          <h1 className="middle-title">How it works</h1>
          <div className="bullets">
            <div className="rectangle">
              <img src={JoinIcon} alt="icon" />
              <p>
                Press JOIN and pay the $0.99 entry fee to join the daily
                competition.
              </p>
            </div>
            <div className="rectangle">
              <img src={ClockIcon} alt="icon" />
              <p>
                Get Ready! At 9PM, Kylie, our virtual host, kicks the column of
                letters and gets them scattered in the arena
              </p>
            </div>
            <div className="rectangle">
              <img src={GuessIcon} alt="icon" />
              <p>
                You have 2 min to guess the scrabbled name and drag each letter
                to its correct place in the frames below the arena.
              </p>
            </div>
            <div className="rectangle">
              <img src={WinnerIcon} alt="icon" />
              <p>
                If you are one of the first 5 players who guessed the name
                correctly, you win a cash prize, with the top prize being $250.
                The sooner you guess, the bigger the prize.
              </p>
            </div>
          </div>
        </div>
        <div id="prizes-section">
          <h1 className="middle-title">Prizes</h1>
          <p>
            We have 25 prizes in store for you, including 5 cash prizes and 20
            coupons that grant you a free entry to the next competition. So, if
            you guess the correct name, you're guaranteed to win something
          </p>
          <div id="boxes">
            <div className="bullets">
              <h3 className="prize" style={{ width: 300 }}>
                1st $250
              </h3>
              <h3 className="prize" style={{ width: 250 }}>
                2nd $150
              </h3>
              <h3 className="prize" style={{ width: 200 }}>
                3rd $50
              </h3>
              <h3 className="prize" style={{ width: 150 }}>
                4th $20
              </h3>
              <h3 className="prize" style={{ width: 100 }}>
                5th $10
              </h3>
            </div>
            <img src={MoneyBag} alt="icon" style={{ width: 200 }} />
          </div>
        </div>
        <div id="lottery-section">
          <h1 className="middle-title">Kylie</h1>
        </div>
      </div>
    );
  }
}
